import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useGetFidelesOfParoisseQuery } from "../../../../../utils/api/fideles/fidele.api";
import { IParoisse } from "../../../../../utils/api/paroisses/paroisses.type";
import { AlertInfo } from "../../../../common/Alert";
import { IFideles } from "../../../../../utils/api/fideles/fideles.type";
import { getAvatar } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../../common/CustomPagination";
import {
  isResponsableParoissien,
  isSuperAdmin,
} from "../../../../../routes/routerUtils";
import { useAppSelector } from "../../../../../redux/hook";
import { UseDeleteFidele } from "../../requestForm/UseCrudFideleForm";
import { BsTrash3 } from "react-icons/bs";
import { MdAddCircleOutline } from "react-icons/md";

type FideleCardPropsType = {
  cover?: string;
  name?: string;
  localisation?: string;
};

function DeleteFidele({ item }: { item: IFideles }) {
  const deleteFidele = UseDeleteFidele(item);
  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteFidele();
  };
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={handleDeleteClick}
      >
        <BsTrash3 />
      </button>
    </div>
  );
}

const FideleCard = ({ item }: { item: IFideles }) => {
  return (
    <NavLink
      to={`/admin/paroisses/detail-fidele/${item.slug}`}
      className="col-md-4 membre-card-wrapper mb-3"
      key={item?.id}
      state={item}
    >
      <div className="custom-fidele-card">
        <span className="fidele-cover-container">
          <img
            src={getAvatar(item?.avatar)}
            alt="photo"
            srcSet=""
            className="w-100 custom-avatar-fidel"
          />
        </span>
        <span className="name">{item?.prenom + " " + item?.nom}</span>
        <span className="localisation">{item?.adresse}</span>
      </div>
    </NavLink>
  );
};

function Fidele({ paroisse }: { paroisse: IParoisse }) {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const { data, isLoading } = useGetFidelesOfParoisseQuery({
    slug: paroisse?.slug,
    page,
    limit,
  });
  let hasAccessMembre = is_SuperAdmin || is_Responsable
  return (
    <div className="row row-paroisse-members">
    {hasAccessMembre ? (
      <div className="d-flex justify-content-end mb-3">
        <NavLink
          to={`/admin/paroisses/${paroisse?.slug}/ajouter-un-fidele`}
          className="btn custom-btn-add-activity"
          state={{
            paroisse,
          }}
        >
          <MdAddCircleOutline className="add-icon" />
          Ajouter un fidèle
        </NavLink>
      </div>
    ) : null}
      {!!isLoading && <p>chargement...</p>}
      {!isLoading && data?.results?.length ? (
        data?.results.map((membre) => (
          <div
            className="col-md-4 membre-card-wrapper position-relative mb-3"
            key={membre?.id}
          >
            <FideleCard item={membre} />
            {/* {!!is_SuperAdmin ? (
              <div className="custom-pos-btn-actions">
                <NavLink
                  to={`/admin/paroisses/${paroisse?.slug}/modifier-un-member`}
                  className="btn btn-action-modal-icon with-tooltip"
                  data-tooltip-content="Modifier"
                  state={
                    membre
                  }
                >
                  <MdOutlineModeEdit />
                </NavLink>
                <DeleteFidele item={membre} />
              </div>
            ) : null} */}
          </div>
        ))
      ) : (
        <div className="py-4">
          <AlertInfo message="Aucune donnée trouvée" />
        </div>
      )}
      <div className="custom-pagination-container">
        <CustomPagination
          nbPages={data?.count}
          page={page}
          onChange={(page, perPage) => {
            setLimit(perPage);
            setPage(page);
          }}
          perPage={limit}
        />
      </div>
    </div>
  );
}

export default Fidele;
