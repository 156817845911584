import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import {
  IUser,
  UserFormData,
  typeUser,
} from "../../../../../utils/api/user/user.type";
import { cleannerError, validatePhone } from "../../../../../utils/Utils";
import { useForm, Controller } from "react-hook-form";
import {
  useGetParoissesQuery,
  useParoisseFromLocation,
} from "../../../../../utils/api/paroisses/paroisses.api";
import { useGetGradesFideleQuery } from "../../../../../utils/api/grades/grades.api";
import {
  useAddOrEditUserMutation,
  useUserFromLocation,
} from "../../../../../utils/api/user/user.api";
import { Data } from "../../../../common/GoogleInput";
import { Color } from "../../../../../utils/theme";
import { Authorisations, FieldType } from "../../../../../utils/constante";
import { roleAuthorizations } from "../../../../common/AutorisationCheckbox";

function UseAddOrEditChefForm(type: typeUser, item?: IUser) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    email: yup.string().email().required().label("L'email"),
    telephone: validatePhone(yup, code),
    paroisse_id: yup.string().required().label("La paroisse").nullable(),
    grade: yup.string().required().label("Le grade").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),

    fonction: yup.string().label("La paroisse").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { slug } = useParams();
  const [paroisse, loading, id, getItem] = useParoisseFromLocation();
  const [address, setAddress] = useState("");
  const [avatar, setAvatar] = useState<any>(null);
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: number }>();

  const [search, setSearch] = useState("");

  const { data: grades = { results: [] } } = useGetGradesFideleQuery({
    limit: 1000,
    q: search,
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditUserMutation();

  useEffect(() => {
    if (type) {
      setValue("user_type", type);
    }
  }, [type]);

  useEffect(() => {
    if (paroisse && slug) {
      setValue("paroisse_id", paroisse?.id);
    } else {
      if (!slug) {
        navigate("/admin/paroisses");
      } else if (!paroisse?.id) {
        setTimeout(() => {
          navigate("/admin/paroisses");
        }, 1000);
      }
    }
  }, [paroisse, paroisse]);

  useEffect(() => {
    if (grades?.results?.length) {
      setOptions(
        grades?.results?.map((el) => {
          return {
            label: el?.titre,
            value: el?.id,
          };
        })
      );
    }
  }, [grades]);

  const handleChangeAvatar = (e) => {
    let file = e?.target?.files[0];
    setAvatar(file);
    setValue("avatar", file);
  };

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  const onSelectGrade = (e) => {
    setOption(e);
    setValue("grade", e?.value);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? "Informations modifiées avec succès !"
          : "Informations ajoutées avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setAvatar(null);
        // fetch data
        if (slug) {
          getItem(slug);
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
      console.log("err", err);
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    console.log("data submitted", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  const isAuthorizedByRole = (itemField: string) => { 
    return roleAuthorizations['default_access'].includes(itemField as FieldType);
};

  useEffect(() => {
    // console.log("item edit",item)
    if (item?.id) {
      let fields: (keyof IUser)[] = [
        "id",
        "slug",
        "nom",
        "telephone",
        "email",
        "adresse",
        "grade",
        "type_fidele",
        "prenom",
        "pays",
        "sexe",
        "date_bapteme",
        "date_dernier_grade",
        "date_nomination",
        "user_type",
        "fonction",
        "dashboard",
        "conseil_administration",
        "conseil_pastoral",
        "paroisses",
        "demandes",
        "cotisations",
        "fideles",
        "formations",
        "messages",
        "office_pastoral",
        "parametres",
        "pays",
        "actualites",
        "preambules",
        "carnet_roses",
        "contitution_bleues",
        "documentations",
        "finances",
        "necrologies",
        "témoignages",
        "affectations",
        "pasteurs",
        "responsable_paroissien",
        "responsable_diocesien",
        "objet_religieux",
        "collectes",
        "actualites",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "paroisse_id" && field !== "grade" && field !== "pays") {
          if (item[field]) setValue(field, item[field]);
        } else {
          if (item[field]?.id) {
            setValue(field, item[field]?.id);
          }
        }
      }

      if (item?.adresse) {
        setAddress(item?.adresse);
      }

      if (item?.telephone) {
        setPhone(item?.telephone);
      }

      if (item?.grade) {
        setOption({
          label: item.grade?.titre,
          value: item.grade?.id,
        });
      }
    } else {
      Authorisations.forEach((item) => {
        if(item?.field === 'dashboard' || isAuthorizedByRole(item?.field)) {
          setValue(item?.field, true);
        } else {
          setValue(item?.field, false);
        }
      });
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    type,
    avatar,
    handleChangeAvatar,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
    control,
    Controller,
    setSearch,
    isSuccess,
    option,
    options,
    onSelectGrade,
  };
}

export default UseAddOrEditChefForm;
