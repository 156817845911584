import React, { useState } from "react";
import "./CeremonieAdmin.css";
import { MdAddCircleOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { AiOutlineMore } from "react-icons/ai";
import { FaPlayCircle } from "react-icons/fa";
import { Ceremonies } from "../../../utils/api/ceremonies/ceremonies.type";
import { getImage, getVideoId } from "../../../utils/Utils";
import { useGetListCeremoniesQuery } from "../../../utils/api/ceremonies/ceremonies.api";
import { FormationSkeleton } from "../FormationAdmin/FormationAdmin";
import { AlertInfo } from "../../common/Alert";
import { CustomPagination } from "../../common/CustomPagination";
import ReactPlayer from "react-player";
import { UseDeleteCeremonies } from "./form/UseAddOrEditCeremonieForm";
import { isResponsableDiocesien, isResponsableParoissien, isSecretaire, isSuperAdmin } from "../../../routes/routerUtils";
import { useAppSelector } from "../../../redux/hook";

const CeremonieCard = ({ item, hasAcces }: { item: Ceremonies, hasAcces: boolean }) => {
  const videoId = getVideoId(item?.lien);
  const onDelete = UseDeleteCeremonies(item?.slug);
  return (
    <div className="ceremonie-card">
      <span className="video-wrapper position-relative">
        <ReactPlayer
          light={
            <img
              src={getImage(item?.image)}
              alt="Thumbnail"
              className="Thumbnail-ceremonies"
            />
          }
          url={
            videoId ? `https://www.youtube.com/embed/${videoId}` : item?.lien
          }
          width="100%"
          height="100%"
        />
      </span>
      <div className="wrapper p-2">
        <span className="title">{item?.titre}</span>
        <span className="sm_link ">
          <a className="video-link no-link" href={item?.lien} target="_blank">
            {item?.lien}
          </a>
        </span>
        <div className="ceremonie_card__footer pt-2 d-flex align-items-center justify-content-end">
          {/* <div className="paroisse-and-views">
            <p className="m-0 pb-2 paroisse">
              <span className="icon">
                <IoLocationSharp />
              </span>
              <span>{paroisse}</span>
            </p>
            <p className="m-0 views">
              <span className="icon">
                <IoEyeSharp />
              </span>
              <span>{number_of_views}</span>
            </p>
          </div> */}
          {hasAcces && <div className="btn-group">
            <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore style={{ fontSize: 18 }} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to={`/admin/ceremonies/modifier/${item?.slug}`}
                  className="dropdown-item no-link"
                  state={item}
                >
                  Modifier
                </NavLink>
              </li>
              <li>
                <button className="dropdown-item"
                  onClick={() => onDelete()}>
                  Supprimer
                </button>
              </li>
            </ul>
          </div>}
        </div>
      </div>
    </div>
  );
};

function CeremonieAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListCeremoniesQuery({
      page,
      limit,
    });
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_ResponsableParoissien = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  const is_ResponsableDiocesien = useAppSelector((s) =>
    isResponsableDiocesien(s.user?.user)
  );
  const is_Secretaire = useAppSelector((s) =>
    isSecretaire(s.user?.user)
  );

  let hasCeremonieAcces = is_SuperAdmin || 
    is_ResponsableParoissien || 
    is_ResponsableDiocesien || 
    is_Secretaire
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Cérémonies</h2>
      </div>

      {hasCeremonieAcces
        && <div className="btn-action-container">
        <NavLink
          role="button"
          to={`/admin/ceremonies/ajouter`}
          className="btn custom-add-btn"
        >
          Ajouter une cérémonie
          <MdAddCircleOutline className="add-icon" />
        </NavLink>
      </div>}

      <div className="ceremonies-page-content">
        <div className="custom-content pt-4">
          <div className="row all-ceremonies-row">
            {!!isLoading && <FormationSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((crmn, key) => (
                  <div
                    className="col-md-3 mb-4 custom-wrapper"
                    key={`${key}_${crmn.id}`}
                  >
                    <CeremonieCard item={crmn} hasAcces={hasCeremonieAcces} key={crmn?.slug} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune cérémonie trouvée" />
              ))}
          </div>

          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CeremonieAdmin;
