import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Authorisations, FieldType } from '../../utils/constante';

const DEFAULT_AUTHORIZATIONS: FieldType[] = [
  'dashboard',
  'contitution_bleues',
  'preambules',
  'formations',
  'ceremonies',
  'documentations',
  'necrologies',
  'actualites',
  'carnet_roses',
  'objet_religieux',
  'témoignages',
  'pasteurs'
];

export const roleAuthorizations: Record<string, FieldType[]> = {
  conseil_pastoral: [
    ...DEFAULT_AUTHORIZATIONS,
    'conseil_pastoral',
    'messages',
    'demandes',
    'paroisses'
  ],
  conseil_administration: [
    ...DEFAULT_AUTHORIZATIONS,
    'conseil_administration',
    'messages',
    'demandes'
  ],
  office_pastoral: [
    ...DEFAULT_AUTHORIZATIONS,
    'office_pastoral'
  ],
  responsable_paroissien: [
    ...DEFAULT_AUTHORIZATIONS,
    'responsable_paroissien'
  ],
  default_access: DEFAULT_AUTHORIZATIONS
};

interface AuthorizationCheckboxesProps {
  register: UseFormRegister<any>;
  userRole?: string | null;
  field?: string;
  disabled?: boolean;
  additionalDisabledFields?: string[];
}


const AuthorizationCheckboxes: React.FC<AuthorizationCheckboxesProps> = ({ register, userRole, field,  disabled, additionalDisabledFields = []}) => {

 const isDefaultAuthorization = (itemField: FieldType) => {
  return DEFAULT_AUTHORIZATIONS.includes(itemField);
};


const isAuthorizedByRole = (itemField: FieldType) => {

  if (!userRole) return isDefaultAuthorization(itemField);
  

  if (roleAuthorizations[userRole]) {
    return roleAuthorizations[userRole].includes(itemField);
  }
  
  // return isDefaultAuthorization(itemField);
};

  return (
    <>
      {Authorisations?.map((item) => {
        // const isDisabled = item.field === 'dashboard' || item.field === field || isAuthorizedByRole(item.field) || disabled || additionalDisabledFields.includes(item.field);
        // const isCheckedByDefault = item.field === 'dashboard' || item.field === field || isAuthorizedByRole(item.field);


        const isDisabled = 
        item.field === 'dashboard' || 
        item.field === field || 
        disabled || 
        isAuthorizedByRole(item.field) ||
        additionalDisabledFields.includes(item.field);

      const isCheckedByDefault = 
        item.field === 'dashboard' || 
        item.field === field || 
        isAuthorizedByRole(item?.field);

        return (
          <label key={item?.field} className={isDisabled ? "disabled" : ""}>
            <input
              type="checkbox"
              {...register(item?.field)}
              id={`checkbox-${item?.field}`}
              disabled={isDisabled}
              defaultChecked={isCheckedByDefault}
            />
            <span>{item?.label}</span>
          </label>
        );
      })}
    </>
  );
};

export default AuthorizationCheckboxes;