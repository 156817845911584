/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import { IParoisse } from "../../../../../utils/api/paroisses/paroisses.type";
import { AiOutlineMore } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import {
  useDeleteForumMutation,
  useGetForumByParoisseQuery,
} from "../../../../../utils/api/forum/forum.api";
import { AlertInfo } from "../../../../common/Alert";
import { Forum } from "../../../../../utils/api/forum/forum.type";
import {
  createMarkup,
  getAvatar,
  getFile,
  getImage,
  getName,
} from "../../../../../utils/Utils";
import { FiTrash2 } from "react-icons/fi";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { CustomPagination } from "../../../../common/CustomPagination";
import { useAppSelector } from "../../../../../redux/hook";
import { isResponsableParoissien, isSuperAdmin } from "../../../../../routes/routerUtils";

export const getField = (item: Forum) => {
  let field = item?.fichier;

  if (item?.content_type === "link") {
    field = item?.link;
  }
  console.log("field", field, item);
  return field;
};

export const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeletePost(slug);
  return (
    <button
      className="dropdown-item d-flex gap-2 align-items-center"
      onClick={() => onDelete()}
    >
      <FiTrash2 />
      Supprimer ce post
    </button>
  );
};

const SocialFeedPostCard = ({ item }: { item: Forum }) => {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  let hasAccess = is_SuperAdmin || is_Responsable
  return (
    <NavLink
      className="no-link"
      to={`/admin/paroisses/communaute/${item?.slug}`}
      state={item}
    >
      <div className="social_post__card">
        <div className="p-3 d-flex justify-content-between align-items-center social_post_header__wrapper">
          <div className="d-flex align-items-center gap-2 poster__infos">
            <picture className="author_image_wrapper">
              <img
                src={getAvatar(item?.author?.avatar)}
                alt="author"
                className="author_image"
              />
            </picture>

            {/* {item?.content_type !== "image"} */}

            <div className="name_and_paroisse">
              <p className="m-0 pb-1 author_name">{getName(item?.author)}</p>
              <p className="m-0 paroisse">{item?.author?.paroisse_id?.nom}</p>
            </div>
          </div>
          <div className="btn-group">
            {hasAccess && <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore style={{ fontSize: 18 }} />
            </button>}
            <ul className="dropdown-menu">
              {/* <li>
              <button className="dropdown-item">Voir plus</button>
            </li> */}
              <li>
                <BtnDelete slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
        <div className="separator"></div>
        <div className="p-3 custom_post__content">
          <p
            className="m-0 pb-3 post_text"
            dangerouslySetInnerHTML={createMarkup(
              item?.content?.slice(0, 200) || ""
            )}
          />

          {item?.content_type === "image" || item?.image ? (
            <p className="m-0 pb-3 post_image__wrapper">
              <img src={getImage(item?.image)} alt={item?.slug} />
            </p>
          ) : item?.content_type !== "text" ? (
            <p className="m-0 pb-3 post_image__wrapper">
              <iframe
                src={getFile(getField(item))}
                className={getField(item)}
                frameBorder={0}
              />
            </p>
          ) : null}

          <div className="d-flex justify-content-between align-items-center post_extras__content">
            <p className="m-0 liked_by d-flex justify-content-between align-items-center gap-3">
              <span className="liked_image_wrapper">
                {[...item?.likes]?.slice(0, 3)?.map((el) => (
                  <img
                    src={getAvatar(el?.user?.avatar)}
                    alt={getName(el?.user) || "avatar"}
                  />
                ))}
              </span>
              <span className="people_whose_liked">
                {`Aimé par ${item?.likes_count} personne(s)`}
              </span>
            </p>
            <span className="comments">
              {item?.comments_count} commentaire(s)
            </span>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

function Communaute({ paroisse }: { paroisse: IParoisse }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetForumByParoisseQuery({
      slug: paroisse?.slug,
      page,
      limit,
    });

  // console.log("data forum", data);

  return (
    <>
      <h4 className="cs-title pb-4">Communauté</h4>
      <div className="community-social-feed-content">
        <div className="row row-social-feed">
          {!!isLoading && <p>Chargement...</p>}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results?.map((item, key) => (
                <div className="col-md-12 mb-4" key={`${key}_${item?.id}`}>
                  <SocialFeedPostCard item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}
        </div>
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
}

export default Communaute;

export function UseDeletePost(slug: string) {
  const [deleteData] = useDeleteForumMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce post ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Le post a été supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
