import React from "react";
import { Contact } from "../../../../utils/api/contact/contact.type";
import {
  closeModal,
  createMarkup,
  formatDateHour,
  getAvatar,
  getName,
} from "../../../../utils/Utils";
import CloseModalBtn, { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import ReactQuill from "react-quill";
import UseResponseForm from "../form/UseResponseForm";
import ErrorMessage from "../../../common/ErrorMessage";

const style = {
  resp_style: {
    margin: "1em 0px 0em 0em",
    fontSize: "20px",
    fontWeight: "500",
  },
  select_style: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "row-reverse",
  },
};

function DetailsMessageModal({
  modalId,
  message,
  hasAccess,
}: {
  message: Contact;
  modalId: string;
  hasAccess?: boolean;
}) {
  const { onSubmit, isLoading, response, handleChange, errors, refInput } =
    UseResponseForm(message);
  return (
    <div
      className="modal fade contact-modal-fade"
      id={modalId}
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-2">
          <div className="wayf-modal-header d-flex justify-content-between align-items-center px-3 ">
            <h1 className="contact-modal-title">
              Répondre à <span>({getName(message?.user)})</span>
            </h1>
            <CloseModalBtnV2 onClick={() => closeModal(modalId)} />
          </div>
          <div className="form-container px-3 py-3">
            <form onSubmit={onSubmit} className="mb-3 border-bottom">
              <div className="row my-4 border-bottom">
                <div className="col-md-12 form-group">
                  <p
                    className="admin-form-label fs-15"
                    dangerouslySetInnerHTML={createMarkup(message?.message)}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 form-group">
                  <label htmlFor="desc-mision" className="admin-form-label">
                    Réponse
                  </label>
                  <ReactQuill
                    ref={refInput}
                    value={response || ""}
                    onChange={(value) => handleChange(value)}
                    readOnly={!hasAccess}
                  />
                  {errors?.response && (
                    <ErrorMessage message={errors?.response?.message} />
                  )}
                </div>
              </div>
              <div className="btn-container gap-3 mb-3">
                <button
                  className="admin-btn-cancel admin-btn-typo"
                  onClick={() => closeModal(modalId)}
                  type="button"
                >
                  Fermer
                </button>
                <button
                  className="admin-btn-add admin-btn-typo "
                  type="submit"
                  disabled={!hasAccess || isLoading}
                >
                  {!isLoading ? (
                    "Envoyer"
                  ) : (
                    <>
                      En cours&nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </>
                  )}
                </button>
              </div>
            </form>
            {message?.responses?.length
              ? message?.responses.map((resp) => (
                  <div className="row my-3">
                    <div className="col-12 itm-center d-flex">
                      <img
                        loading="lazy"
                        className="avatar-auteur-response-card mx-1"
                        src={getAvatar(resp?.user?.avatar)}
                        alt={"Avatar" + getName(resp?.user)}
                        width={"50"}
                        height={"50"}
                      />
                      <p className="text-response-card fs-12 fw-300 sender-name">
                        Par&nbsp;
                        <strong>{getName(resp?.user)}</strong>
                        <br />
                        <span className="cm-sender-email">
                          {resp?.user?.email}{" "}
                        </span>
                        <br />
                        <span className="cm-sender-email">
                          {formatDateHour(resp?.created_at)}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 itm-center mx-1">
                      <p
                        className="text-response-card fs-12 fw-300"
                        style={style.resp_style}
                        dangerouslySetInnerHTML={{ __html: resp?.response }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsMessageModal;
