import React from "react";
import PhoneInput from "react-phone-input-2";
import { useLocationState } from "../../../utils/Utils";
import UseCrudMembreParoisse from "./requestForm/UseCrudMembreParoisse";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import ErrorMessage from "../../common/ErrorMessage";
import { Authorisations } from "../../../utils/constante";
import { useParoisseFromLocation } from "../../../utils/api/paroisses/paroisses.api";
import { useUserFromLocation } from "../../../utils/api/user/user.api";
import AuthorizationCheckboxes from "../../common/AutorisationCheckbox";

export const Wrapper = ({
  children,
  customClass,
}: {
  children?: any;
  customClass?: string;
}) => {
  return <div className={`custom-big-wrapper ${customClass}`}>{children}</div>;
};
function AddMembreParoisse() {
  const field = useLocationState<string>(undefined);
  const [item] = useParoisseFromLocation()
  const [membre] = useUserFromLocation();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
  } = UseCrudMembreParoisse(item, field, membre);

  // console.log({field})
  // console.log({item})
  // console.log({membre})
  let typeofMembre = typeof(membre)
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb
            pageName="Paroisse"
            secondPage={(!!membre && typeofMembre !== "string") ? "Modifier un membre" : "Ajouter un membre"}
          />
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <Wrapper customClass="mt-3">
          <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
            <div className="custom-wrapper-title">
              Informations personnelles
            </div>
          </div>
          <div className="form-content">
            <div className="row row-infos-perso">
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors.nom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors.prenom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors.email}
                  required
                />
              </div>
              <div className="col-md-6">
                {/* <Input
                  label="Téléphone"
                  type="numeric"
                  placeholder="Téléphone"
                  {...register("telephone")}
                  error={errors.telephone}
                /> */}
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Rôle"
                  id="role"
                  placeholder="Rôle"
                  {...register("role")}
                  error={errors.role}
                />
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <Input
                    type="file"
                    label="Ajouter une photo"
                    id="avatar"
                    placeholder="Ajouter une photo"
                    accept="image/*"
                    onChange={(e) => handelAvatarChange(e)}
                    name="avatar"
                  />
                  {errors?.avatar && (
                    <ErrorMessage message={errors?.avatar?.message} />
                  )}
                  {previewAvatar && (
                    <div className="img-preview-container mb-3">
                      <img src={previewAvatar} className="type-img-preview" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>

        <Wrapper customClass="mt-3">
          <div className="custom-wrapper-title pb-5">Habilitations</div>
          <div className="container-checkbox-input">
            <div className="form-checkbox-input checkbox-input">
              <div className="custom-permission-container">
              <AuthorizationCheckboxes
                  register={register} 
                  userRole={'default_access'}
                  field={field}
                />
                {/* {Authorisations?.map((item) => (
                  <label key={item?.field} className={item?.field === `${field}` || item?.field === "dashboard" ? "disabled" : ""}>
                    <input
                      type="checkbox"
                      {...register(item?.field)}
                      id={`checkbox-${item?.field}`}
                      disabled={
                        item?.field === `${field}` ||
                        item?.field === "dashboard"
                      }
                    />
                    <span>{item?.label}</span>
                  </label>
                ))} */}
              </div>
            </div>
          </div>

          <div className="col-md-4 offset-md-8 pt-5">
            <button
              className="btn btn-theme-admin w-100"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>{(!!membre && typeofMembre !== "string") ? "Modification..." : "Ajout..."}</span>
                </>
              ) : (
                (!!membre && typeofMembre !== "string") ? "Modifier" : "Ajouter"
              )}
            </button>
          </div>
        </Wrapper>
      </form>
    </>
  );
}

export default AddMembreParoisse;
